import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PolyIcons from '../poly-icons';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import {
  CalculatorContainer,
  ReportsContainer,
  ConversionPanel,
} from '../EvalCalculatorStyles';
import RangeSlider from './range-slider/range-slider';
import Icon from '../../../styles/atoms/icons';
import PersistLink from '../../../components/PersistLink/PersistLink';

const ReportsContent = ({
  range = [20, 80],
  grossProfit,
  netProfit,
  netProfitPercentage,
  lowerQuartile,
  upperQuartile,
  minimum,
  maximum,
  onShare,
}) => (
  <CalculatorContainer className="reports">
    <PolyIcons />
    <ReportsContainer className="result" range={range}>
      <Container className="px-0">
        <Row>
          <Col md={8} className="text-center mb-4 mb-md-0">
            <p className="result-title">Estimated Value of Your Business</p>
            <h2 className="amount-range">
              ${lowerQuartile.toLocaleString()} - $
              {upperQuartile.toLocaleString()}
            </h2>
            <div className="dots-wrapper">
              <div className="left">
                <p className="label">Lowest</p>
                <h6>${minimum.toLocaleString()}</h6>
                <div className="left-v-dots" />
              </div>
              <div className="center-dots">
                <div className="dots-v-center" />
                <div className="bottom-dots" />
              </div>
              <div className="right">
                <p className="label">Highest</p>
                <h6>${maximum.toLocaleString()}</h6>
                <div className="right-v-dots" />
              </div>
            </div>
            <RangeSlider range={range} />
            <p className="disclaimer">
              Disclaimer: The value provided by this calculator is intended to
              assist in seeking financial guidance and is not intended to
              replace financial advice. Valuations vary based on business
              conditions, geography and economic conditions among other reasons.
              Shopmonkey makes no warranty or representation as to the results
              of this calculation.
            </p>
          </Col>
          <Col md={4}>
            <p className="result-title">Company Metrics</p>
            <div className="result-card">
              <p className="px-0 col-7 c-key">Gross Profit</p>
              <h4 className="px-0 col-5 c-value">
                ${(Math.round(grossProfit * 100) / 100).toLocaleString()}
              </h4>
            </div>
            <div className="result-card">
              <p className="px-0 col-7 c-key">Net Income</p>
              <h4 className="px-0 col-5 c-value">
                ${(Math.round(netProfit * 100) / 100).toLocaleString()}
              </h4>
            </div>
            <div className="result-card">
              <p className="px-0 col-7 c-key">Gross Profit %</p>
              <h4 className="px-0 col-5 c-value">
                {Math.round(netProfitPercentage * 100) / 100}%
              </h4>
            </div>

            <div className="action-buttons">
              <PersistLink to="#!" className="btn btn-dark d-none">
                <Icon id="FiDownload" /> Download report
              </PersistLink>
              <Button type="button" variant="outline-dark" onClick={onShare}>
                <Icon id="FiSend" />
                Share this Resource
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </ReportsContainer>
    <ConversionPanel>
      <Container className="text-center">
        <div className="roi-wrapper">
          <h3 className="roi-title">
            Thousands of shops across the U.S. and Canada are growing their
            business by partnering with Shopmonkey.
          </h3>
          <p className="quote">
            “With Shopmonkey, we have been able to grow the shop by 20% in gross
            sales since implementing it in the shop.”
          </p>
          <p className="name">
            <strong>Frank Powell</strong>, Top Shop Auto
          </p>
          <CallToAction
            variant="secondary"
            link="/case-studies/top-shop-auto"
            value="View Top Shop Auto Case Study"
          />
        </div>
      </Container>
    </ConversionPanel>
  </CalculatorContainer>
);

export default ReportsContent;

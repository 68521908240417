import axios from 'axios';
import { graphql } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import GeneralHead from '../../components/generalHead';
import HeaderBusinessCalculator from '../../components/Header/HeaderBusinessCalculator';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ShareResourceModal from '../../components/ShareResourceModal';
import { getHubspotUtk } from '../../utils/getHubspotUtk';
import { getNumberValue } from '../../utils/getNumberValue';
import ReportsContent from './reports/reports-content';
import StepContent from './steps/step-content';
import SubmitModal from './success-modal';

const EvalCalculator = () => {
  const hubspotFormId = '96b0c2f7-9f9d-4e7a-b164-777ef3ac0f54';
  const [step, setStep] = useState(1);
  const [hutk, setHutk] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [availableUTMs, setAvailableUTMs] = useState({});
  const [getStartedFormData, setGetStartedFormData] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    company: '',
    shop_type: 'none',
  });

  const [stepsContent, setStepsContent] = useState({
    bvc_annual_gross_revenue: '',
    bvc_annual_cost_of_sales: '',
    bvc_over_head_expenses: '',
    bvc_total_asset_value: '',
    numemployees: 'none',
    bay_count: 'none',
    existing_shop_monkey_user: 'No',
  });

  const [reportData, setReportData] = useState({
    grossProfit: 0,
    netProfit: 0,
    netProfitPercentage: 0,
  });

  const [reportValues, setReportValues] = useState({
    maximum: 0,
    upper: 0,
    lower: 0,
    minimum: 0,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHutk(getHubspotUtk(document));

      if (JSON.parse(window.sessionStorage.getItem('utms'))) {
        setAvailableUTMs(JSON.parse(window.sessionStorage.getItem('utms')));
      }
    }
  }, []);

  const handleStep = useCallback(stepNumber => setStep(stepNumber), []);
  const handleSubmit = useCallback(() => {
    setOpenModal(true);
    if (
      stepsContent.bvc_annual_gross_revenue &&
      stepsContent.bvc_annual_cost_of_sales &&
      stepsContent.bvc_over_head_expenses
    ) {
      const grossProfit =
        getNumberValue(stepsContent.bvc_annual_gross_revenue) -
        getNumberValue(stepsContent.bvc_annual_cost_of_sales);
      const ebitda =
        grossProfit - getNumberValue(stepsContent.bvc_over_head_expenses);
      const ebitdaPercent =
        ((getNumberValue(stepsContent.bvc_annual_gross_revenue) -
          getNumberValue(stepsContent.bvc_annual_cost_of_sales)) /
          getNumberValue(stepsContent.bvc_annual_gross_revenue)) *
        100;
      setReportData({
        ...reportData,
        grossProfit,
        netProfit: ebitda,
        netProfitPercentage: ebitdaPercent,
      });

      const ebitdaMultiplier = (ebitda / 2000000) * 5 + 2;
      let median = ebitda * (ebitdaMultiplier >= 7 ? 7 : ebitdaMultiplier);
      if (ebitda > 0) {
        setReportValues({
          ...reportValues,
          maximum: Math.round(median * 1.2 * 100) / 100,
          upper: Math.round(median * 1.1 * 100) / 100,
          lower: Math.round(median * 0.9 * 100) / 100,
          minimum: Math.round(median * 0.8 * 100) / 100,
        });
      } else {
        median = getNumberValue(stepsContent.bvc_total_asset_value);
        setReportValues({
          ...reportValues,
          maximum:
            Math.round(median * 1.1 * 100) / 100 < 0
              ? 0
              : Math.round(median * 1.1 * 100) / 100,
          upper:
            Math.round(median * 1.05 * 100) / 100 < 0
              ? 0
              : Math.round(median * 1.05 * 100) / 100,
          lower:
            Math.round(median * 0.95 * 100) / 100 < 0
              ? 0
              : Math.round(median * 0.95 * 100) / 100,
          minimum:
            Math.round(median * 0.9 * 100) / 100 < 0
              ? 0
              : Math.round(median * 0.9 * 100) / 100,
        });
      }
    }
  }, [
    reportData,
    reportValues,
    stepsContent.bvc_annual_cost_of_sales,
    stepsContent.bvc_annual_gross_revenue,
    stepsContent.bvc_over_head_expenses,
    stepsContent.bvc_total_asset_value,
  ]);

  const isNumberField = useCallback(field => {
    return (
      field === 'bvc_annual_gross_revenue' ||
      field === 'bvc_annual_cost_of_sales' ||
      field === 'bvc_over_head_expenses' ||
      field === 'bvc_total_asset_value'
    );
  }, []);

  const handleViewReport = useCallback(() => {
    setStep(8);
    setOpenModal(false);

    const url = `https://api.hsforms.com/submissions/v3/integration/submit/5424293/${hubspotFormId}`;
    const fields = [];
    Object.keys(getStartedFormData).forEach(key => {
      fields.push({ name: key, value: getStartedFormData[key] });
    });

    Object.keys(stepsContent).forEach(key => {
      if (isNumberField(key)) {
        fields.push({ name: key, value: getNumberValue(stepsContent[key]) });
      } else {
        fields.push({ name: key, value: stepsContent[key] });
      }
    });

    if (availableUTMs) {
      Object.keys(availableUTMs).forEach(key => {
        if (availableUTMs[key] != null) {
          fields.push({ name: key, value: availableUTMs[key] });
        }
      });
    }

    const postData = {
      submittedAt: Date.now(),
      fields,
      context: {
        hutk,
        pageUri: 'https://www.shopmonkey.io/business-valuation-calculator',
        pageName: 'Business Valuation Calculator Page',
        sfdcCampaignId: '7014x000000QRHFAA4',
      },
    };

    axios
      .post(url, postData)
      .then()
      .catch(err =>
        // eslint-disable-next-line no-console
        console.log(`Business Valuation Calculator Form Error - ${err}`)
      );
  }, [availableUTMs, getStartedFormData, hutk, isNumberField, stepsContent]);

  return (
    <Layout
      forceShowHeader={step === 1 || step === 8}
      forceShowFooter
      forceAnnouncement
    >
      {step !== 1 && step !== 8 && (
        <HeaderBusinessCalculator currentStep={step} />
      )}
      {step !== 8 && (
        <StepContent
          step={step}
          stepsContent={stepsContent}
          setStepsContent={setStepsContent}
          onStep={handleStep}
          onSubmit={handleSubmit}
        />
      )}
      {step === 8 && (
        <ReportsContent
          grossProfit={reportData.grossProfit}
          netProfit={reportData.netProfit}
          netProfitPercentage={reportData.netProfitPercentage}
          lowerQuartile={reportValues.lower}
          upperQuartile={reportValues.upper}
          minimum={reportValues.minimum}
          maximum={reportValues.maximum}
          onShare={() => setOpenShareModal(true)}
        />
      )}
      <SubmitModal
        backdrop="static"
        show={openModal}
        formData={getStartedFormData}
        setFormData={setGetStartedFormData}
        onSubmit={handleViewReport}
      />
      <ShareResourceModal
        kicker="Business Valuation Calculator"
        heading="Share this Resource"
        url="shopmonkey.io/business-valuation-calculator"
        show={openShareModal}
        onHide={() => setOpenShareModal(false)}
      />
    </Layout>
  );
};

export const Head = ({
  data: {
    businessValuationCalculator: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export const query = graphql`
  query BusinessValuationCalculatorQuery {
    businessValuationCalculator: datoCmsBusinessValuationCalculator {
      indexable
      headHtml
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      schemaMarkup {
        id
        schema
      }
    }
  }
`;

export default EvalCalculator;
